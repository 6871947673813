import { Injectable, OnDestroy, inject } from '@angular/core';
import { EventProfile, OnlineEventTicketOrder, PaymentStatus, PaymentType, TicketOrderStatus } from 'in-time-core';
import { BehaviorSubject, Observable, Subscription, of, switchMap } from 'rxjs';
import { ResourceSnapshot } from '../core/models/resource-snapshot';
import { DatabaseService } from './database.service';
import { AuthenticationService } from './authentication.service';
import { ErrorType } from '../core/models/error-type';

export interface MyTicketsData {
  orders: OnlineEventTicketOrder[],
  events: Map<string, EventProfile>,
}

async function fetchCustomerOrders(
  output: BehaviorSubject<ResourceSnapshot<MyTicketsData>>,
  databaseService: DatabaseService,
  customerId: string
): Promise<void> {
  const orderResponse = await databaseService.fetchCustomerTicketOrders(customerId, (order) => {
    if(order.status !== TicketOrderStatus.Open ||
      order.paymentType === PaymentType.Online && order.onlinePaymentDetails?.status !== PaymentStatus.Succeeded
    ) {
      return false;
    }

    return true;
  });

  if(orderResponse.success) {
    let orders: OnlineEventTicketOrder[] = orderResponse.data;
    const uniqueEventIds: Set<string> = new Set<string>();

    for(let i = 0 ; i < orders.length; i++) {
      if(orders[i].items.length > 0 && orders[i].items[0].tickets.length > 0) {
        uniqueEventIds.add(orders[i].items[0].tickets[0].eventId);
      }
    }

    const eventResponse = await databaseService.fetchEventProfiles([...uniqueEventIds]);
    if(eventResponse.success) {
      const events = eventResponse.data.reduce((map, e) => map.set(e.uniqueId, e), new Map<string, EventProfile>());

      orders = orders.filter((o) => {
        if(o.items.length > 0 && o.items[0].tickets.length > 0) {
          return events.has(o.items[0].tickets[0].eventId);
        }

        return false;
      });

      orders.sort((a, b) => b.creationDate.valueOf() - a.creationDate.valueOf());
      output.next(ResourceSnapshot.success({ orders, events }));
    }
    else {
      output.next(ResourceSnapshot.error(eventResponse.error));
    }
  }
  else {
    output.next(ResourceSnapshot.error(orderResponse.error));
  }
}

@Injectable({ providedIn: 'root' })
export class MyTicketsService implements OnDestroy {
  private readonly authenticationService = inject(AuthenticationService);
  private readonly databaseService = inject(DatabaseService);

  private readonly orderSnapshots: BehaviorSubject<ResourceSnapshot<MyTicketsData>>;
  private subscription: Subscription | null = null;
  private _isInitialized: boolean = false;

  get snapshots$(): Observable<ResourceSnapshot<MyTicketsData>> {
    return this.orderSnapshots;
  }

  get isInitialized(): boolean {
    return this._isInitialized;
  }

  constructor() {
    this.orderSnapshots = new BehaviorSubject<ResourceSnapshot<MyTicketsData>>(
      ResourceSnapshot.uninitialized()
    );
  }

  async maybeInit(): Promise<void> {
    if(this._isInitialized) {
      return;
    }

    this._isInitialized = true;

    const fetchingStream = this.authenticationService.authState$.pipe(
      switchMap((authState) => {
        if(!authState.isAuthenticated || authState.user == null) {
          return of(ResourceSnapshot.error<MyTicketsData>(ErrorType.NotAuthenticated));
        }

        const output = new BehaviorSubject(ResourceSnapshot.loading<MyTicketsData>());
        fetchCustomerOrders(output, this.databaseService, authState.user.uid);

        return output;
      }),
    );

    this.subscription = fetchingStream.subscribe((snapshot) => this.orderSnapshots.next(snapshot));
  }

  ngOnDestroy(): void {
    if(this.subscription != null) {
      this.subscription?.unsubscribe();
      this.subscription = null;
      this.orderSnapshots.complete();
    }
  }
}