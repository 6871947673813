
export interface IInTimeApiEndpointProvider {
  createOnlineTicketOrder: string;
  createOnlineProductOrder: string;
  markOrderWithFailedPayment: string;
  abortOrder: string;
  lockSeatSelection: string,
  createPoiReview: string,
  createClientFromCustomerAccount: string,
  validateTaxForm: string,
  fetchArtistPageInfo: string;
  fetchBrandPageInfo: string;
  fetchOrganizerPageInfo: string;
}

export const InTimeApiEndpointProvider: IInTimeApiEndpointProvider = {
  createOnlineTicketOrder: 'createOnlineTicketOrder',
  createOnlineProductOrder: 'createOnlineProductOrder',
  markOrderWithFailedPayment: 'markOrderWithFailedPayment',
  abortOrder: 'abortOrder',
  lockSeatSelection: 'lockSeatSelection',
  createPoiReview: 'createPoiReview',
  createClientFromCustomerAccount: 'createClientFromCustomerAccount',
  validateTaxForm: 'validateTaxForm',
  fetchArtistPageInfo: 'fetchArtistPageInfo',
  fetchBrandPageInfo: 'fetchBrandPageInfo',
  fetchOrganizerPageInfo: 'fetchOrganizerPageInfo',
}
